import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { UserResponse } from 'src/types';

interface FilterForm {
  username: string;
  email: string;
  firstName: string;
  lastName: string;
}

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  private urlBase = 'v2/users';

  constructor(private http: HttpClient) { }

  get(filter: FilterForm, { pageSize = 1, pageNumber = 1 } = {}, sort = ''): Observable<UserResponse> {
    let params = new HttpParams();
    params = this.setFilter(filter, params);
    if (sort) {
      params = params.append('sort', sort);
    }
    params = params.append('pageSize', pageSize.toString());
    params = params.append('pageNumber', (pageNumber + 1).toString());
    return this.http.get<UserResponse>(`${environment.apiUrl}/${this.urlBase}`, { params });
  }

  getAll(filter: FilterForm = {} as FilterForm, sort = ''): Observable<UserResponse> {
    let params = new HttpParams();
    params = this.setFilter(filter, params);
    if (sort) {
      params = params.append('sort', sort);
    }
    return this.http.get<UserResponse>(`${environment.apiUrl}/${this.urlBase}`, { params });
  }

  private setFilter(filter: FilterForm, params: HttpParams): HttpParams {
    if (filter.username) {
      params = params.append('filter', `username,~,%${filter.username}%`);
    }
    if (filter.email) {
      params = params.append('filter', `email,~,%${filter.email}%`);
    }
    if (filter.firstName) {
      params = params.append('filter', `firstName,~,%${filter.firstName}%`);
    }
    if (filter.lastName) {
      params = params.append('filter', `lastName,~,%${filter.lastName}%`);
    }
    return params;
  }
}
